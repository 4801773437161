// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-area-distribusi-js": () => import("./../../../src/pages/AreaDistribusi.js" /* webpackChunkName: "component---src-pages-area-distribusi-js" */),
  "component---src-pages-category-alat-ident-kesehatan-ternak-js": () => import("./../../../src/pages/category/AlatIdentKesehatanTernak.js" /* webpackChunkName: "component---src-pages-category-alat-ident-kesehatan-ternak-js" */),
  "component---src-pages-category-desinfektan-js": () => import("./../../../src/pages/category/Desinfektan.js" /* webpackChunkName: "component---src-pages-category-desinfektan-js" */),
  "component---src-pages-category-farmasetik-js": () => import("./../../../src/pages/category/Farmasetik.js" /* webpackChunkName: "component---src-pages-category-farmasetik-js" */),
  "component---src-pages-category-feed-adsp-js": () => import("./../../../src/pages/category/FeedADSP.js" /* webpackChunkName: "component---src-pages-category-feed-adsp-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/ContactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-produk-js": () => import("./../../../src/pages/Produk.js" /* webpackChunkName: "component---src-pages-produk-js" */),
  "component---src-pages-sektor-akuakultur-js": () => import("./../../../src/pages/sektor/Akuakultur.js" /* webpackChunkName: "component---src-pages-sektor-akuakultur-js" */),
  "component---src-pages-sektor-feedmill-js": () => import("./../../../src/pages/sektor/Feedmill.js" /* webpackChunkName: "component---src-pages-sektor-feedmill-js" */),
  "component---src-pages-sektor-hewan-besar-js": () => import("./../../../src/pages/sektor/HewanBesar.js" /* webpackChunkName: "component---src-pages-sektor-hewan-besar-js" */),
  "component---src-pages-sektor-hewan-ksygn-js": () => import("./../../../src/pages/sektor/HewanKsygn.js" /* webpackChunkName: "component---src-pages-sektor-hewan-ksygn-js" */),
  "component---src-pages-sektor-unggas-js": () => import("./../../../src/pages/sektor/Unggas.js" /* webpackChunkName: "component---src-pages-sektor-unggas-js" */),
  "component---src-pages-tentang-kami-js": () => import("./../../../src/pages/TentangKami.js" /* webpackChunkName: "component---src-pages-tentang-kami-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/product-template.js" /* webpackChunkName: "component---src-templates-product-template-js" */),
  "component---src-templates-sub-category-template-js": () => import("./../../../src/templates/subCategory-template.js" /* webpackChunkName: "component---src-templates-sub-category-template-js" */)
}

